import logo from './logo.svg';
import './App.css';

//wucm2VasMbexFQPUYHArzBN8f4v7S93

//061eef71caa947a3b82c8dbda8bbdf63

const queryParameters = new URLSearchParams(window.location.search)
const type = queryParameters.get("type")
const name = queryParameters.get("name")

const json = {"api": "HedgeFi"}

function App() {
  return (
    <pre>{JSON.stringify(json, null, 2)}</pre>
  );
}

export default App;
